import { Button, Input, Box, TextField, Link, Paper, FormHelperText, CircularProgress } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { CancelIcon } from "./assets/icons/landing-page.icons";
import { Logo } from "./assets/icons/landing-page.icons";
import Earning2 from "./assets/images/1_Cropped.png";
import Earning1 from "./assets/images/Earning1.png";
import PrintMaterials from "./assets/images/print_materials.png";
import LawnServices from "./assets/images/landingPageSwiper/LawnServices.png";
import HomeCare from "./assets/images/landingPageSwiper/HomeCare.png";
import MachineRepair from "./assets/images/landingPageSwiper/MachineRepair.png";
import MassageServices from "./assets/images/landingPageSwiper/MassageServices.png";
import PetGrooming from "./assets/images/landingPageSwiper/PetGrooming.png";
import SalonServices from "./assets/images/landingPageSwiper/SalonServices.png";
import Cleaning from "./assets/images/landingPageSwiper/CleaningServices.png";
import Quote from "./assets/images/Quote.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import "./assets/style/landing-page.conatiners.style.css";
import { useState } from "react";
import { race } from "redux-saga/effects";
import axios from "axios";
import { landingPageValidationSchemas } from "./landing-page-validation-schema";
import { required_error } from "@get-busy/common";
import { z } from "zod";
import { toast } from "react-toastify";
import logo from "../../.././../static/images/get-busy-new-logo-copy-2.png";
const initilErrorState = {
  email: null,
  name: null,
  phone: null,
  services: null,
  city: null,
}

const initialState = {
  email: "",
  name: "",
  phone: "",
  companyName: ""
}
export const LandingPageContainers = () => {
  const [isProcessing, setIsProcessing] = useState(false);


  const [formData, setFormData] = useState<{
    email: string;
    name: string;
    phone?: string;
    companyName?: string;

  }>(initialState);

  const [validationErrors, setValidationErrors] = useState<any>(initilErrorState);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleValidation = (inputField: any, value: any) => {
    try {
      landingPageValidationSchemas[inputField].parse(value);
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [inputField]: null,
      }));
    } catch (error: any) {
      const errorMessage =
        error.errors?.[0]?.message || error.message || `Invalid ${inputField}`;
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [inputField]: errorMessage,
      }));
    }
  };

  function onClickGetStarted(evt) {
    evt.preventDefault();
    let isName = false;
    let isemail = false;
    try {
      landingPageValidationSchemas["name"].parse(formData.name)
      isName = true;
      landingPageValidationSchemas["email"].parse(formData.email)
      isemail = true;
      setIsProcessing(true)
      axios.post(
        'https://us-central1-getbusy-v0.cloudfunctions.net/hubspot-function',
        formData,
      ).then(res => {
        console.log(res);
        if (res.status >= 200 && res.status <= 299) {
          toast.success("We appreciate your interest! Our team will contact you shortly.", {
            autoClose: 5000,
          });
          setFormData(initialState)
          return;
        }
        if (res.status == 409) {
          toast.error("Email already exists, please use different email", {
            autoClose: 5000,
          });
          return;
        }
        toast.error("something went wrong, please try after sometime", {
          autoClose: 5000,
        });
      })
        .catch(err => {
          console.log(err)
          toast.error("something went wrong, please try after sometime", {
            autoClose: 5000,
          });
        }).finally(() =>
          setIsProcessing(false));
    } catch (error: any) {
      console.log(error);
      if (error instanceof z.ZodError) {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          ...(!isName ? { "name": error.issues[0].message } : { "email": error.issues[0].message })
        }));
      }

    }
  }

  let inputStyle = {
    background: "#EDF0F7",
    borderRadius: "9px"
  }
  return (
    <div className="container-new w-full  h-full flex justify-center items-center ">
      <Paper className=" sm:max-w-[630px] mx-4 p-5 sm:mx-9 sm:p-10 rounded-3xl " elevation={4}>
        <div className=" flex justify-center items-start flex-col gap-6">
          <div className="flex items-center justify-center">
            <img
              className="object-contain object-center"
              src={logo}
              width={40}
              alt="logo"
            />
            <div className="text-3xl font-bold">
              Ops-Planner
            </div>
          </div>
          <div className="flex justify-start items-start gap-3 sm:gap-10 flex-col sm:flex-row">
            <div className="flex flex-col gap-5 w-full sm:w-3/5 ">

              <div className="text">
                Enterprise-Grade AI Planning for Every Manufacturing Floor
              </div>
              <div className="child-text flex flex-col gap-2	">
                <div><strong>Prevent stockouts and reduce inventory costs</strong> through AI-powered planning that considers weather forecasts, local events, supply chain disruptions, and market trends.
                  <br />
                </div>
                <div><strong>Connect to your existing systems with simple instructions</strong>, and start optimizing operations immediately—no overhead required.
                  <br />
                </div>
                <div>
                  <strong>Ops-Planner speaks your language</strong>, so your team can start making data-driven decisions instantly—no technical training required.
                  <br />
                </div>
                <div>Start planning smarter with Ops-Planner Never say <strong>"Wish We Planned It Better!"</strong> again.
                </div>
                <div>
                  <strong>Read More</strong>: {" "}
                  <Link href="https://docs.google.com/document/d/1G398RPiJJovu_S3yBl-imO6KuWG78PRiXj_M-nsQjJ4/edit?usp=sharing" >
                    AI-Driven Sales Forecasting
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4  w-full sm:w-2/5">
              <TextField
                placeholder="Name"
                label="Name"
                variant="outlined"
                name="name"
                fullWidth
                type="text"
                size="small"
                inputProps={{
                  style: inputStyle
                }}
                value={formData.name}
                onChange={handleFormChange}
                FormHelperTextProps={{
                  error: !!validationErrors?.name,
                }}
                {... !!validationErrors?.name && {
                  helperText: validationErrors?.name,
                }}

              />
              <TextField
                placeholder="Company Name"
                label="Company Name"
                variant="outlined"
                name="companyName"
                fullWidth
                type="text"
                size="small"
                inputProps={{
                  style: inputStyle
                }}
                value={formData.companyName}
                onChange={handleFormChange}
              />
              <TextField
                placeholder="Email"
                label="Email"
                variant="outlined"
                name="email"
                fullWidth
                type="text"
                size="small"
                inputProps={{
                  style: inputStyle
                }}
                value={formData.email}
                onChange={handleFormChange}

                FormHelperTextProps={{
                  error: !!validationErrors?.email,
                }}
                {... !!validationErrors?.email && {
                  helperText: validationErrors?.email,
                }}
              />
              <TextField
                placeholder="Phone(Optional)"
                label="Phone"
                variant="outlined"
                name="phone"
                fullWidth
                type="text"
                size="small"
                inputProps={{
                  style: inputStyle
                }}
                value={formData.phone}
                onChange={handleFormChange}
              />

              <Button variant="contained" onClick={onClickGetStarted}>
                {isProcessing && <CircularProgress className="mr-3" size={20} sx={(theme) => ({
                  color: theme.palette.grey[200],
                  ...theme.applyStyles('dark', {
                    color: theme.palette.grey[800],
                  }),
                })} />}
                Get Early Access
              </Button>
            </div>
          </div>
          <footer className="py-3.5">
            <p className="text-white m-0 text-center text-xs text-gray-500">
              © Elevate Software and Services, 2025.
              All rights reserved.
            </p>
          </footer>
        </div>
      </Paper >
    </div>
  );
};
