import { colors, CssBaseline, responsiveFontSizes } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import React, { memo, useState } from "react";

interface ThemeWrapperComponentProps {
  children: React.ReactElement;
}
/**
 * Theme provider component used to change the theme of the application.
 * @returns {JSX} ThemeWrapper component .
 */
export const ThemeWrapper = memo((props: ThemeWrapperComponentProps) => {
  const breakpointsFull = {
    breakpoints: createBreakpoints({
      values: {
        xs: 0,
        sm: 600,
        md: 760,
        lg: 992,
        xl: 1200,
      },
    }),
  };
  const [theme] = useState({
    palette: {
      primary: {
        main: "#1A5CB2",
        light: colors.blue[100],
        dark: colors.blue[700],
      },
      mode: "light",
      secondary: {
        main: "#54B916",
        light: colors.blue[100],
        dark: colors.blue[700],
      },
    },
    typography: {
      fontFamily: "Sora, sans-serif",
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider
      theme={responsiveFontSizes(createTheme(theme as any, breakpointsFull))}
    >
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
});
