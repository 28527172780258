import { invalid_type_error, required_error, short_value_message, validation_error_message } from "@get-busy/common";
import { z } from "zod";

export const landingPageValidationSchemas: any = {
    name: z
        .string()
        .refine((value) => value.trim() !== "", { message: required_error })
        .refine((value) => value.length > 0 && value.length >= 1, {
            message: short_value_message,
        }),
    email: z
        .string()
        .refine((value) => value.trim() !== "", { message: required_error })
        .refine((value) => value.length > 0, { message: invalid_type_error })
        .refine((value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
            message: validation_error_message.email.type,
        })
        .refine((value) => value.length > 0, {
            message: validation_error_message.email.value,
        }),
};
