import { GetBusyRoutes } from "@get-busy/common";
import { ThemeWrapper, ToastComponent } from "@get-busy/components";
import { getBusyRoutes } from "@get-busy/routes";
import { Paper } from "@mui/material";
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify';

export function AppComponent() {
  const getRoutes = (routes?: GetBusyRoutes[]) =>
    routes?.map(({ element: Component, key, path, childRoutes, to }) => (
      <Route
        key={key}
        path={path}
        element={
          <>
            {Component && <Component to={to} />}
            {childRoutes?.length && <Outlet />}
          </>
        }
      >
        {getRoutes(childRoutes)}
      </Route>
    ));

  return (
    <ThemeWrapper>
      <>
        <ToastContainer />
        <Paper elevation={0} className="w-100 h-full">
          <Routes>{getRoutes(getBusyRoutes)}</Routes>
        </Paper>
        <ToastComponent {...({} as any)} />
      </>
    </ThemeWrapper>
  );
}
